import { LitElement, ReactiveControllerHost } from 'lit';

export class PliInputController {
    _value = '';
    host: ReactiveControllerHost & LitElement;

    constructor(host: ReactiveControllerHost & LitElement) {
        this.host = host;
        host.addController(this);
    }

    hostConnected() {}

    inputHandler = (e: KeyboardEvent) => {
        this._value = (e.currentTarget as HTMLInputElement).value;
        const event: PliInputChangeEvent = new CustomEvent('change', {
            composed: true,
            detail: { value: this._value },
        });
        this.host.dispatchEvent(event);
    };
}

export type PliInputChangeEvent = CustomEvent<{ value: string }>;
